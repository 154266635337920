@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: "Open Sans", sans-serif !important;
    background-color: #000 !important;
    font-weight: 300 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #fff !important;
}

a {
    text-decoration: none !important;
}

ul > li {
    margin-bottom: 0.5em !important;
}

.description-wrapper p {
    white-space: pre-line;
}
nav {
    background-color: transparent;
    transition: 0.5s ease;
    z-index: 1000;
}

.navbar {
    background-color: #000;
    border-bottom: 1px solid #fff;
}

nav .navbar-brand {
    color: #fff !important;
    font-size: 1.5em;
    font-weight: bold;
}

nav li a {
    list-style: none;
    padding: 10px !important;
    font-size: 15px !important;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #fff !important;
    margin: 0 5px;
    font-weight: 400 !important;
    transition: 0.5s ease !important;
}

nav li a:hover {
    background-color: #fff;
    color: #000 !important;
}

.navbar-brand .logo {
    width: 125px;
}

.logout {
    color: #fff !important;
}

.home-page {
    position: relative;
    width: 100%;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-page::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.home-page img {
    width: 100%;
    height: 740px;
    object-fit: cover;
    filter: blur(2px) grayscale(0.4);
    opacity: 0.35;
}

.home-page .hs-item {
    font-size: 20px;
}

.home-page .hs-item-title {
    position: absolute;
    color: #fff;
    font-size: 45px;
    font-weight: 700;
    text-align: center;
    letter-spacing: 1px;
}

.scrolldown {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 40px;
    height: 40px;
    transform: translateY(-80px) translateX(-50%) rotate(45deg);
}

.scrolldown span {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    animation: animate 1.5s linear infinite;
    opacity: 0;
}

.scrolldown span:nth-child(1) {
    transform: translate(-15px, -15px);
    animation-delay: -0.4s;
}
.scrolldown span:nth-child(2) {
    transform: translate(0, 0);
    animation-delay: -0.2s;
}
.scrolldown span:nth-child(3) {
    transform: translate(15px, 15px);
    animation-delay: 0s;
}

@keyframes animate {
    0% {
        top: -5px;
        left: -5px;
        opacity: 0;
    }
    25% {
        top: 0;
        left: 0;
        opacity: 1;
    }
    50%,
    100% {
        top: 5px;
        left: 5px;
        opacity: 0;
    }
}

.message-info {
    color: #5c5c5c;
    line-height: 1.5;
    margin-bottom: 20px;
    /* opacity: 0.5; */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    border: 1px solid crimson;
    position: relative;
    padding: 15px 20px;
    border-left-width: 44px;
    z-index: 1;
}

.message-info::before {
    content: "\f071";
    font-family: fontAwesome;
    font-weight: 300;
    font-size: 25px;
    color: #fff;
    margin-top: -18px;
    position: absolute;
    left: -44px;
    top: 50%;
    width: 44px;
    text-align: center;
}

.message-info a {
    text-decoration: underline !important;
}

.card {
    border-radius: 20px !important;
    -webkit-box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 6px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 6px;
    background-color: transparent !important;
    border: 1px solid #bfbfbf !important;
}

.card.product {
    min-height: 300px;
}

.card img {
    height: 150px;
    padding: 10px;
    border-radius: 20px;
}

@media screen and (max-width: 440px) {
    .card img {
        object-fit: contain;
    }
}

.card-body {
    bottom: 0;
    background: #000000e6;
    color: #fff !important;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    width: 100%;
}

.card .icon {
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
    display: none;
}

.card:hover .icon {
    display: block;
    z-index: 1;
}

.description-wrapper {
    max-height: 250px;
    overflow: auto;
}

p {
    color: #bfbfbf;
}

.buttons {
    padding: 20px 0 40px;
    text-align: center;
}

button.button {
    -webkit-appearance: none;
    font-family: inherit;
    appearance: none;
    background: none;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    padding: 10px;
    outline: none;
    opacity: 0.7;
    border: none;
    color: #fff;
    margin: 0 5px;
}

button.button:hover,
button.button.active {
    opacity: 1;
    border-bottom: 1px solid;
    transition: 0.2s ease;
}

input[type="radio"] {
    --s: 1em; /* control the size */
    --c: #009688; /* the active color */

    height: var(--s);
    aspect-ratio: 1;
    border: calc(var(--s) / 8) solid #939393;
    padding: calc(var(--s) / 8);
    background: radial-gradient(farthest-side, var(--c) 94%, #0000) 50%/0 0 no-repeat content-box;
    border-radius: 50%;
    outline-offset: calc(var(--s) / 10);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    font-size: inherit;
    transition: 0.3s;
    margin-right: 10px;
}

input[type="radio"]:checked {
    border-color: var(--c);
    background-size: 100% 100%;
}

input[type="radio"]:disabled {
    background: linear-gradient(#939393 0 0) 50%/100% 20% no-repeat content-box;
    opacity: 0.5;
    cursor: not-allowed;
}

label.product {
    border: 1px solid;
    border-radius: 30px;
    cursor: pointer;
}

label.product.selected {
    border: 2px solid #009688;
    border-radius: 30px;
}

.content h6 {
    color: #000 !important;
}

ol li {
    color: #fff;
    margin: 0.5em 0;
}

.content {
    display: flex;
    align-content: center;
    justify-content: space-between;
    margin: 0.5em 0;
    color: #fff;
}

.quantity {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 150px;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 2px 13px #0000001a;
}

.quantity button {
    background-color: #4b4c4d;
    color: #fff;
    border: none;
    font-size: 20px;
    width: 30px;
    height: auto;
    text-align: center;
    transition: 0.5s ease;
}

.quantity button:hover {
    background-color: #949494;
    color: #000;
}

.input-box::-webkit-inner-spin-button,
.input-box::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.input-box[type="number"] {
    -moz-appearance: textfield;
}

.feedback-input {
    outline: 0;
    width: 100%;
    padding: 13px;
    color: white;
    font-weight: 500;
    line-height: 22px;
    border-radius: 5px;
    margin-bottom: 15px;
    transition: all 0.3s;
    box-sizing: border-box;
    border: 2px solid #bfbfbf7a;
    background-color: transparent;
}

.feedback-input:focus {
    border: 2px solid;
}

label {
    color: #fff;
}

.ql-container.ql-disabled .ql-tooltip {
    display: none;
}
.ql-editor p {
    font-size: 15px;
}

.fa-trash {
    background-color: crimson;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
    border-radius: 50%;
    font-size: 20px;
    transition: 0.5s;
}

.fa-trash:hover {
    background-color: rgb(136, 0, 27);
}

.error {
    border: 1px solid crimson;
}

.contact i {
    font-size: 60px;
}

.footer {
    position: relative;
}

.footer::before {
    position: absolute;
    content: "";
    width: 80%;
    left: 50%;
    top: 5%;
    transform: translate(-50%, -50%);
    height: 1px;
    background-color: #bfbfbf;
    margin: auto;
}

.footer-basic {
    margin-top: 2em;
    padding: 40px 0;
    background-color: transparent;
    color: #4b4c4d;
}

.footer-basic ul {
    padding: 10px;
    list-style: none;
    text-align: center;
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 0;
}

.footer-basic li {
    padding: 0 10px;
}

.footer-basic ul a {
    color: inherit;
    text-decoration: none;
    opacity: 0.8;
}

.footer-basic ul a:hover {
    opacity: 1;
}

.footer-basic .social {
    text-align: center;
}

.footer-basic .social > a {
    font-size: 24px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    border: 1px solid #ccc;
    margin: 0 8px;
    color: inherit;
    /* opacity: 0.75; */
}

.footer-basic .social > a:hover {
    opacity: 0.9;
}

.footer-basic .copyright {
    margin-top: 15px;
    text-align: center;
    font-size: 13px;
    color: #aaa;
    margin-bottom: 0;
}

.telegram {
    position: fixed;
    bottom: 40px;
    right: 40px;
    font-size: 2em;
    background-color: #039be5;
    border-radius: 50px;
    width: 50px;
    height: 50px;
    text-align: center;
    z-index: 100;
    transition: 0.5s ease;
}

.telegram:hover {
    -ms-transform: scale(1.5); /* IE 9 */
    -webkit-transform: scale(1.5); /* Safari 3-8 */
    transform: scale(1.2);
}

#message {
    height: 200px;
}

.spinner {
    width: 300px;
    height: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.half-spinner {
    width: 50px;
    height: 50px;
    border: 3px solid #fff;
    border-top: 3px solid transparent;
    border-radius: 50%;
    animation: spin 0.5s linear 0s infinite;
}

.completed {
    font-size: 2rem;
    color: #fff;
    animation: bigger 1s linear;
}

@keyframes bigger {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(2);
    }
}

@keyframes spin {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
